
<template>
  <div>
    <span class="text-center table-edit-icon" @click="popupActive = true">
      <i
        v-if="params.data.designation_active == 4"
        class="text-center material-icons"
        >unarchive</i
      >
      <i v-else class="text-center material-icons">archive</i>
    </span>
    <div class="demo-alignment">
      <vs-popup
        :id="'popmodalA' + params.data.designation_id"
        :button-close-hidden="true"
        class="holamundo p-2"
        title="Designation Archive"
        :active.sync="popupActive"
      >
        <div class="text-center">
          <h5 v-if="params.data.designation_active == '4'">
            Do you want to unarchive?
          </h5>
          <h5 v-else-if="params.data.designation_active == '1' || '2'">
            Do you want to archive?
          </h5>
          <!-- SUBMIT BUTTON -->
          <div class="vx-row mt-5">
            <div class="vx-col mx-auto">
              <!-- :disabled="submitStatus" -->
              <vs-button
                color="success"
                type="filled"
                @click="onChangeCheckbox($event)"
                class="mr-3"
                >Yes</vs-button
              >
              <vs-button color="danger" type="filled" @click="privStatus()"
                >No</vs-button
              >
            </div>
          </div>
        </div>
      </vs-popup>
    </div>
  </div>
  <!--  -->
</template>

<script>
import Vue from "vue";
import designationServices from "@/services/designationService.js";

export default Vue.extend({
  data() {
    return {
      params: null,
      popupActive: false,
      form: {
        designation_active: "4",
      },
      copy_designation_active: null,
    };
  },
  beforeMount() {},
  beforeDestroy() {
    let _id = document.getElementById(
      "popmodalA" + this.params.data.designation_id
    );
    //console.log("_id", _id);
    _id.setAttribute("style", "display: none;");
  },
  methods: {
    onChangeCheckbox: function (e, value) {
      this.popupActive = false;

      console.log("this.params", this.params);
      this.$vs.loading();
      let id = this.params.data.designation_id;
      let c_id = this.params.data.company_id;
      let payload = {
        designation_name: this.params.data.designation_name,
        // company_id: this.form.company_id,
        designation_description: this.params.data.designation_description || "",
        designation_active:
          this.params.data.designation_active == "4" ? "1" : "4",
        company_id: c_id,
      };

      console.log(`payload`, payload);
      designationServices
        .editDesignation(payload, id)
        .then((response) => {
          this.$vs.loading.close();
          const { data } = response;
          if (data.Status == true || data.Status == "true") {
            this.$vs.notify({
              title: "Updated!",
              text: data.Message,
              iconPack: "feather",
              icon: "check_box",
              color: "success",
            });
            setTimeout(() => {
              eventBus.$emit("refreshTabledesi", data);
            }, 1);
          } else {
            this.$vs.loading.close();
            console.log("data :", data);
            this.$vs.notify({
              title: "something went wrong",
              text: data.Message,
              iconPack: "feather",
              icon: "check_box",
              color: "warning",
            });
          }
        })
        .catch((err) => {
          this.$vs.loading.close();
        });
    },
    privStatus: function () {
      this.popupActive = false;
      this.form.designation_active = this.copy_designation_active;
    },
  },
});
</script>